import { Tabs, Title, createStyles } from '@mantine/core';
import { IconApiApp, IconUserEdit } from '@tabler/icons-react';
import { useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';

import { SellerAccount } from './SellerAccount';
import { EditUser } from './EditUser';

const useStyles = createStyles(() => ({
  header: {
    marginBottom: 20,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  content: {
    height: 'calc(100vh - 225px)',
  },
  tabs: {
    width: '100%',
  },
}));

export const Settings = () => {
  const { classes } = useStyles();

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (!searchParams.get('tab')) {
      setSearchParams({ tab: 'editUser' });
    }
  }, []);

  const tabs = useRef([
    {
      label: 'Edit User',
      value: 'editUser',
      icon: <IconUserEdit />,
      component: <EditUser />,
    },
    {
      label: 'Seller Account',
      value: 'sellerAccount',
      icon: <IconApiApp />,
      component: <SellerAccount />,
    },
  ]);

  const onTabChange = (tabValue) => {
    setSearchParams({ tab: tabValue });
  };

  return (
    <>
      <div className={classes.header}>
        <Title order={2}>Account Settings</Title>
      </div>
      <div className={classes.content}>
        <Tabs
          variant="outline"
          defaultValue={searchParams.get('tab') || tabs.current[0].value}
          className={classes.tabs}
          onTabChange={onTabChange}
        >
          <Tabs.List position="left" pl={15}>
            {tabs.current.map((tab) => (
              <Tabs.Tab key={tab.value} value={tab.value} icon={tab.icon}>
                {tab.label}
              </Tabs.Tab>
            ))}
          </Tabs.List>
          {tabs.current.map((tab) => (
            <Tabs.Panel key={tab.value} value={tab.value} className={classes.tabs} p="md">
              {tab.component}
            </Tabs.Panel>
          ))}
        </Tabs>
      </div>
    </>
  );
};
