import { createBrowserRouter, createRoutesFromElements, Navigate, Route, RouterProvider } from 'react-router-dom';

import { Auth, Activate, CustomsTracking, Help, Settings } from '../screens';
import { ProtectedRoute, DeactivateRoute } from '../components';
import { UserLayout, AuthLayout } from '../layout';

export const Navigation = () => {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="/">
        <Route
          path="/"
          element={
            <ProtectedRoute redirectTo="/auth">
              <UserLayout />
            </ProtectedRoute>
          }
        >
          <Route path="/" element={<Navigate to="/customs-tracking" />} />
          <Route path="settings" element={<Settings />} />
          <Route path="customs-tracking" element={<CustomsTracking />} />
          <Route path="help" element={<Help />} />
        </Route>
        <Route
          path="/auth"
          element={
            <DeactivateRoute redirectTo="/">
              <AuthLayout />
            </DeactivateRoute>
          }
        >
          <Route path="" element={<Auth />} />
          <Route path="activate" element={<Activate />} />
        </Route>
      </Route>,
    ),
  );

  return <RouterProvider router={router} />;
};
