import { MantineThemeOverride } from '@mantine/core';

export const theme: MantineThemeOverride = {
  colorScheme: 'light',
  fontFamily: `'Public Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 
							'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif`,
  headings: {
    sizes: {
      h1: {
        fontSize: '36px',
        fontWeight: '500',
      },
      h2: {
        fontSize: '30px',
        fontWeight: '500',
      },
      h3: {
        fontSize: '24px',
        fontWeight: '500',
      },
      h4: {
        fontSize: '20px',
        fontWeight: '400',
      },
      h5: {
        fontSize: '18px',
        fontWeight: '400',
      },
      h6: {
        fontSize: '16px',
        fontWeight: '400',
      },
    },
  },
  black: '#000',
  white: '#fff',
  defaultRadius: 'sm',
  radius: { xs: '0.125rem', sm: '0.25rem', md: '0.5rem', lg: '1rem', xl: '2rem' },
  dir: 'ltr',
  fontSizes: { xs: '0.75rem', sm: '0.875rem', md: '1rem', lg: '1.125rem', xl: '1.25rem' },
  spacing: { xs: '0.625rem', sm: '0.75rem', md: '1rem', lg: '1.25rem', xl: '1.5rem' },
  lineHeight: 1.55,
  primaryColor: 'blue',
  primaryShade: { light: 6, dark: 8 },
  components: {
    Input: {
      defaultProps: {
        size: 'md',
      },
    },
    Select: {
      defaultProps: {
        size: 'md',
      },
    },
    TextInput: {
      defaultProps: {
        size: 'md',
      },
    },
    PasswordInput: {
      defaultProps: {
        size: 'md',
      },
    },
  },
};
