import {
  Group,
  Pagination,
  ScrollArea,
  Table,
  UnstyledButton,
  Text,
  Center,
  Select,
  createStyles,
  rem,
  LoadingOverlay,
} from '@mantine/core';
import { useEffect, useState } from 'react';
import { IconChevronDown } from '@tabler/icons-react';

enum SortingDirection {
  ASC = 'asc',
  DESC = 'desc',
}

const useStyles = createStyles((theme) => ({
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    flex: '1 0',
  },
  table: {
    tableLayout: 'auto',
    textAlign: 'center',
    height: '100%',
    borderBottom: '0.0625rem solid #dee2e6',
    position: 'relative',
    top: 0,
  },
  header: {
    position: 'sticky',
    top: 0,
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
    transition: 'box-shadow 150ms ease',
    border: 'none !important',
  },

  th: {
    textAlign: 'center',
  },

  control: {
    width: '100%',
    padding: `${theme.spacing.xs} ${theme.spacing.md}`,
  },

  icon: {
    width: rem(21),
    height: rem(21),
    borderRadius: rem(21),
  },

  scrollArea: {
    height: '100%',
    border: '0.0625rem solid #dee2e6',
    borderTop: 'none',
    borderBottom: 'none',
  },

  pagination: {
    display: 'flex',
    justifyContent: 'flex-end',

    padding: '25px 50px',
    border: '0.0625rem solid #dee2e6',

    '& > * + *': {
      marginLeft: 20,
    },
  },
}));

interface TableColumnProps {
  name: string;
  cell: (row) => JSX.Element;
  selector?: string;
  sortable?: boolean;
  width?: number | string;
}
interface TableProps {
  data: { [key: string]: any }[];
  columns: TableColumnProps[];
  total: number;
  onPaginationChange?: (page?: number, size?: number) => void;
  onSortingChange?: (direction: SortingDirection, field: string) => void;
  isLoading?: boolean;
}

export const CustomTable: React.FC<TableProps> = ({
  data,
  columns,
  total,
  onPaginationChange,
  onSortingChange,
  isLoading,
}) => {
  const { classes } = useStyles();

  const [pagination, setPagination] = useState({ page: 0, size: 0 });
  const [sorting, setSorting] = useState({ direction: SortingDirection.ASC, field: '' });

  const onPageChange = (page: number) => {
    setPagination({
      page: page,
      size: pagination.size,
    });
  };

  const onPageSizeChange = (pageSize: string) => {
    setPagination({
      page: pagination.page,
      size: Number(pageSize),
    });
  };

  useEffect(() => {
    if (onPaginationChange) {
      onPaginationChange(pagination.page, pagination.size);
    }

    if (onSortingChange) {
      onSortingChange(sorting.direction, sorting.field);
    }
  }, [pagination, sorting]);

  const onSortChange = (column) => {
    setSorting({
      direction: sorting.direction === SortingDirection.ASC ? SortingDirection.DESC : SortingDirection.ASC,
      field: column.selector,
    });
  };

  return (
    <div className={classes.container}>
      <ScrollArea className={classes.scrollArea}>
        <LoadingOverlay visible={!!isLoading} />
        <Table className={classes.table} horizontalSpacing="lg" verticalSpacing="md" highlightOnHover>
          <thead className={classes.header}>
            <tr>
              {columns.map((column, columnIndex) => (
                <th
                  key={columnIndex}
                  style={{
                    width: column.width || 'auto',
                    textAlign: 'center',
                    border: 'none !important',
                    borderTop: '0.0625rem solid #dee2e6',
                  }}
                >
                  {column.sortable ? (
                    <UnstyledButton className={classes.control} onClick={() => onSortChange(column)}>
                      <Group position="center">
                        <Text fw={500} fz="sm">
                          {column.name}
                        </Text>
                        <Center className={classes.icon}>
                          <IconChevronDown size="0.9rem" stroke={1.5} />
                        </Center>
                      </Group>
                    </UnstyledButton>
                  ) : (
                    column.name
                  )}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((row) => (
              <tr key={row.name}>
                {columns.map((column, columnIndex) => (
                  <td key={row.name + columnIndex}>{column.cell(row)}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
      </ScrollArea>
      <div className={classes.pagination}>
        {total > 0 && (
          <Select
            w={80}
            allowDeselect
            type="number"
            defaultValue={'10'}
            data={['10', '20', '100']}
            onChange={onPageSizeChange}
          />
        )}
        <Pagination total={total} onChange={onPageChange} />
      </div>
    </div>
  );
};
