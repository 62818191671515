/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n  mutation Activate($email: String!, $code: String!) {\n    activate(email: $email, code: $code)\n  }\n": types.ActivateDocument,
    "\n  query CustomsTrackings {\n    customsTrackings {\n      items {\n        status\n        shipmentId\n        orderId\n        updatedAt\n        duration\n        MRN\n      }\n      meta {\n        total\n        unread\n      }\n    }\n  }\n": types.CustomsTrackingsDocument,
    "\n  mutation Login($email: String!, $password: String!) {\n    login(email: $email, password: $password)\n  }\n": types.LoginDocument,
    "\n  query Marketplaces {\n    marketplaces {\n      country\n      marketplaceId\n    }\n  }\n": types.MarketplacesDocument,
    "\n  query RetrieveMyUser {\n    retrieveMyUser {\n      _id\n      firstName\n      lastName\n      email\n      marketplace\n      hasSellerAccount\n      sellerApis {\n        amazon {\n          refresh_token\n          client_id\n          client_secret\n          marketplaceId\n        }\n      }\n    }\n  }\n": types.RetrieveMyUserDocument,
    "\n  mutation Register($firstName: String!, $lastName: String!, $email: String!, $password: String!, $returnLink: String!) {\n    register(firstName: $firstName, lastName: $lastName, email: $email, password: $password, returnLink: $returnLink) {\n      _id\n      email\n    }\n  }\n": types.RegisterDocument,
    "\n  mutation UpdateMyUser($input: MyUserUpdateInput!) {\n    updateMyUser(input: $input)\n  }\n": types.UpdateMyUserDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation Activate($email: String!, $code: String!) {\n    activate(email: $email, code: $code)\n  }\n"): (typeof documents)["\n  mutation Activate($email: String!, $code: String!) {\n    activate(email: $email, code: $code)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query CustomsTrackings {\n    customsTrackings {\n      items {\n        status\n        shipmentId\n        orderId\n        updatedAt\n        duration\n        MRN\n      }\n      meta {\n        total\n        unread\n      }\n    }\n  }\n"): (typeof documents)["\n  query CustomsTrackings {\n    customsTrackings {\n      items {\n        status\n        shipmentId\n        orderId\n        updatedAt\n        duration\n        MRN\n      }\n      meta {\n        total\n        unread\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation Login($email: String!, $password: String!) {\n    login(email: $email, password: $password)\n  }\n"): (typeof documents)["\n  mutation Login($email: String!, $password: String!) {\n    login(email: $email, password: $password)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query Marketplaces {\n    marketplaces {\n      country\n      marketplaceId\n    }\n  }\n"): (typeof documents)["\n  query Marketplaces {\n    marketplaces {\n      country\n      marketplaceId\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query RetrieveMyUser {\n    retrieveMyUser {\n      _id\n      firstName\n      lastName\n      email\n      marketplace\n      hasSellerAccount\n      sellerApis {\n        amazon {\n          refresh_token\n          client_id\n          client_secret\n          marketplaceId\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query RetrieveMyUser {\n    retrieveMyUser {\n      _id\n      firstName\n      lastName\n      email\n      marketplace\n      hasSellerAccount\n      sellerApis {\n        amazon {\n          refresh_token\n          client_id\n          client_secret\n          marketplaceId\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation Register($firstName: String!, $lastName: String!, $email: String!, $password: String!, $returnLink: String!) {\n    register(firstName: $firstName, lastName: $lastName, email: $email, password: $password, returnLink: $returnLink) {\n      _id\n      email\n    }\n  }\n"): (typeof documents)["\n  mutation Register($firstName: String!, $lastName: String!, $email: String!, $password: String!, $returnLink: String!) {\n    register(firstName: $firstName, lastName: $lastName, email: $email, password: $password, returnLink: $returnLink) {\n      _id\n      email\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateMyUser($input: MyUserUpdateInput!) {\n    updateMyUser(input: $input)\n  }\n"): (typeof documents)["\n  mutation UpdateMyUser($input: MyUserUpdateInput!) {\n    updateMyUser(input: $input)\n  }\n"];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;