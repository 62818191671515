import { useMutation } from '@tanstack/react-query';

import { graphql } from '../../api';
import { useClient } from './useClient';

const activateMutation = graphql(`
  mutation Activate($email: String!, $code: String!) {
    activate(email: $email, code: $code)
  }
`);

export const useActivate = () => {
  const client = useClient();

  return useMutation({
    mutationFn: async (formData: any) => {
      return client.request(activateMutation, {
        email: formData.email,
        code: formData.code,
      });
    },
  });
};
