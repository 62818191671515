import { Input, Title, UnstyledButton, createStyles } from '@mantine/core';
import { IconAlertTriangleFilled, IconEdit, IconSearch, IconSend } from '@tabler/icons-react';
import { useDebouncedState } from '@mantine/hooks';
import { useEffect } from 'react';

import { CustomTable } from '../components';
import { useCustomsTrackingList } from '../hooks';

const useStyles = createStyles(() => ({
  header: {
    marginBottom: 20,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  content: {
    height: 'calc(100vh - 225px)',
  },
}));

export const CustomsTracking = () => {
  const columns = [
    {
      name: 'Status',
      cell: (row) => <IconAlertTriangleFilled />,
      sortable: false,
      width: 200,
    },
    {
      name: 'Shipment ID',
      cell: (row) => row.shipmentId,
      selector: 'shipmentId',
      sortable: true,
    },
    {
      name: 'Order ID',
      cell: (row) => row.orderId,
    },
    {
      name: 'Last update',
      cell: (row) => row.updatedAt,
    },
    {
      name: 'Duration',
      cell: (row) => row.duration,
    },
    {
      name: 'Place',
      cell: (row) => row.address?.country,
    },
    {
      name: 'MRN',
      cell: (row) => row.MRN,
    },
    {
      name: 'Actions',
      cell: (row) => (
        <>
          <UnstyledButton>
            <IconEdit />
          </UnstyledButton>
          <UnstyledButton>
            <IconSend />
          </UnstyledButton>
        </>
      ),
    },
  ];

  const {
    isLoading,
    data: { customsTrackings: { items: customsTrackingList, meta: customsTrackingMeta } } = {
      customsTrackings: { items: [], meta: { total: 0 } },
    },
    remove: freeList,
  } = useCustomsTrackingList();

  useEffect(() => {
    return () => {
      freeList();
    };
  }, []);

  const { classes } = useStyles();

  const [searchValue, setSearchValue] = useDebouncedState('', 200);

  return (
    <>
      <div className={classes.header}>
        <Title order={2}>Customs Tracking of shipping list</Title>
        <Input
          icon={<IconSearch size="1rem" stroke={1.5} />}
          onChange={(event) => setSearchValue(event.target.value)}
          placeholder="Search"
        />
      </div>
      <div className={classes.content}>
        <CustomTable
          isLoading={isLoading}
          columns={columns}
          data={customsTrackingList || []}
          total={customsTrackingMeta.total}
        />
      </div>
    </>
  );
};
