import { useInputState } from '@mantine/hooks';
import { PasswordInput, Text, Center, Box, Progress, Group, PasswordInputProps } from '@mantine/core';
import { IconCheck, IconX } from '@tabler/icons-react';

import { passwordRequirements } from '../constants';

const PasswordRequirement = ({ meets, label }: { meets: boolean; label: string }) => {
  return (
    <Text color={meets ? 'teal' : 'red'} mt={5} size="sm" inline>
      <Center inline>
        {meets ? <IconCheck size="0.9rem" stroke={1.5} /> : <IconX size="0.9rem" stroke={1.5} />}
        <Box ml={7}>{label}</Box>
      </Center>
    </Text>
  );
};

const getStrength = (password: string) => {
  let multiplier = password.length > 5 ? 0 : 1;

  passwordRequirements.forEach((requirement) => {
    if (!requirement.re.test(password)) {
      multiplier += 1;
    }
  });

  return Math.max(100 - (100 / (passwordRequirements.length + 1)) * multiplier, 0);
};

export const PasswordStrength = (props: PasswordInputProps) => {
  const [value, setValue] = useInputState('');
  const strength = getStrength(value);
  const checks = passwordRequirements.map((requirement, index) => (
    <PasswordRequirement key={index} label={requirement.label} meets={requirement.re.test(value)} />
  ));
  const bars = Array(4)
    .fill(0)
    .map((_, index) => (
      <Progress
        styles={{ bar: { transitionDuration: '0ms' } }}
        value={value.length > 0 && index === 0 ? 100 : strength >= ((index + 1) / 4) * 100 ? 100 : 0}
        color={strength > 80 ? 'teal' : strength > 50 ? 'yellow' : 'red'}
        key={index}
        size={4}
      />
    ));

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (props?.onChange) {
      props.onChange(event);
    }

    setValue(event);
  };

  return (
    <div>
      <PasswordInput {...props} value={value} onChange={onChange} />
      <Group spacing={5} grow mt="xs" mb="md">
        {bars}
      </Group>
      <PasswordRequirement label="Has at least 6 characters" meets={value.length > 5} />
      {checks}
    </div>
  );
};
