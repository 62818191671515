import { useQuery } from '@tanstack/react-query';

import { graphql } from '../../api';
import { useClient } from './useClient';
import { TokenService } from '../../services';

const retrieveMyUserQuery = graphql(`
  query RetrieveMyUser {
    retrieveMyUser {
      _id
      firstName
      lastName
      email
      marketplace
      hasSellerAccount
      sellerApis {
        amazon {
          refresh_token
          client_id
          client_secret
          marketplaceId
        }
      }
    }
  }
`);

export const useMyUser = () => {
  const client = useClient();
  const userToken = TokenService.getToken();
  return useQuery(
    ['myUser', userToken],
    async () => {
      try {
        return client.request(retrieveMyUserQuery);
      } catch (error) {
        TokenService.removeToken();
        return { retrieveMyUser: null };
      }
    },
    { staleTime: Infinity },
  );
};
