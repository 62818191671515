import { createStyles } from '@mantine/core';

import LogoImage from '../assets/images/logo.png';
import { Account } from '../components';

const useStyles = createStyles(() => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '1px solid rgb(211, 211, 211)',
  },
  logo: {
    width: 300,
    borderRight: '1px solid rgb(211, 211, 211)',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export const Navbar = () => {
  const { classes } = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.logo}>
        <img src={LogoImage} alt="" />
      </div>
      <Account />
    </div>
  );
};
