/* eslint-disable camelcase */
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { notifications } from '@mantine/notifications';
import { IconCheck, IconLoader, IconSpiral, IconX } from '@tabler/icons-react';

import { graphql } from '../../api';
import { useClient } from './useClient';
import { TokenService } from '../../services';

const updateMyUser = graphql(`
  mutation UpdateMyUser($input: MyUserUpdateInput!) {
    updateMyUser(input: $input)
  }
`);

const updateNotificationId = 'update-my-user';

export const useUpdateMyUser = () => {
  const client = useClient();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (formData: any) => {
      notifications.show({ id: updateNotificationId, message: 'Saving..', loading: true });
      return client.request(updateMyUser, {
        input: formData,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['myUser', TokenService.getToken()]);
      notifications.update({
        id: updateNotificationId,
        message: 'User updated successfully!',
        color: 'green',
        icon: <IconCheck />,
      });
    },
    onError: () => {
      notifications.update({
        id: updateNotificationId,
        message: 'Something went wrong!',
        color: 'red',
        icon: <IconX />,
      });
    },
  });
};
