import { Outlet } from 'react-router-dom';
import { Paper, createStyles } from '@mantine/core';

import LogoImage from '../assets/images/logo.png';
import BackgroundImage from '../assets/images/main-background.png';

const useStyles = createStyles(theme => ({
  container: {
    width: '100vw',
    height: '100vh',
    background: `url(${BackgroundImage}) no-repeat center center`,
    backgroundSize: 'cover',
    position: 'relative',
  },
  paper: {
    width: 440,
    background: '#fff',
    borderRadius: theme.radius.md,
    backgroundColor: theme.white,
    padding: '50px 0',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
    display: 'inline-flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  logo: {
    marginBottom: 25,
  },
  tabs: {
    width: '100%',
  },
}));

export const AuthLayout = () => {
  const { classes } = useStyles();
  return (
    <div className={classes.container}>
      <Paper className={classes.paper} withBorder radius="md" shadow="md">
        <img className={classes.logo} src={LogoImage} alt="" />
        <Outlet />
      </Paper>
    </div>
  );
};
