import { Outlet } from 'react-router-dom';
import { createStyles } from '@mantine/core';

import { Navbar, Sidebar } from '../components';

const useStyles = createStyles(() => ({
  layoutContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    width: '100vw',
  },
  container: {
    display: 'flex',
    height: '100%',
  },
  contentWrapper: {
    flex: '1 0',
  },
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    background: '#fff',
    padding: '45px 50px',
  },
}));

export const UserLayout = () => {
  const { classes } = useStyles();
  return (
    <div className={classes.layoutContainer}>
      <Navbar />
      <div className={classes.container}>
        <Sidebar />
        <div className={classes.contentWrapper}>
          <div className={classes.content}>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};
