import { GraphQLClient } from 'graphql-request';

import { TokenService } from '../../services';
import { config } from '../../config';

export const useClient = () => {
  const authToken = TokenService.getToken();
  const client = new GraphQLClient(`${config.API_URL}/en/graphql`, {
    headers: authToken
      ? {
          authorization: `Bearer ${authToken}`,
        }
      : undefined,
  });
  return client;
};
