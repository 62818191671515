import { useQuery } from '@tanstack/react-query';

import { graphql } from '../../api';
import { useClient } from './useClient';

const retrieveMarketplaceQuery = graphql(`
  query Marketplaces {
    marketplaces {
      country
      marketplaceId
    }
  }
`);

export const useMarketplaces = () => {
  const client = useClient();
  return useQuery(
    ['marketplaces'],
    async () => {
      try {
        return client.request(retrieveMarketplaceQuery);
      } catch (error) {
        return { marketplaces: [] };
      }
    },
    { staleTime: Infinity },
  );
};
