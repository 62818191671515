import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { MantineProvider } from '@mantine/core';
import { Notifications } from '@mantine/notifications';

import { theme } from './theme';
import { Navigation } from './navigation';

const queryClient = new QueryClient();

const App = () => (
  <QueryClientProvider client={queryClient}>
    <MantineProvider withGlobalStyles withNormalizeCSS theme={theme}>
      <Notifications />
      <Navigation />
    </MantineProvider>
  </QueryClientProvider>
);

export default App;
