import { Button, Input, PasswordInput, createStyles, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useNavigate } from 'react-router-dom';

import { useRegister } from '../../hooks';
import { PasswordStrength } from '../../components';
import { passwordRequirements } from '../../constants';

const useStyles = createStyles(() => ({
  form: {
    '& > div': {
      textAlign: 'left',
      width: '100%',
      marginBottom: 20,
    },
  },
}));

export const Register = () => {
  const { classes } = useStyles();

  const register = useRegister();

  const navigate = useNavigate();

  const form = useForm({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      confirmPassword: '',
    },
    validate: {
      firstName: (value) => !value,
      lastName: (value) => !value,
      email: (value) => !value || (!/^\S+@\S+$/.test(value) ? 'Invalid email' : null),
      password: (value, values) => {
        return !value || !!passwordRequirements.find((req) => !req.re.test(value));
      },
      confirmPassword: (value, values) => !value || (value !== values.password ? 'Passwords did not match' : null),
    },
  });

  const onSubmit = async (values) => {
    try {
      await register.mutateAsync(values);
      // res.register
      navigate({ pathname: '/auth/activate', search: `?email=${values.email}` }, { replace: true });
    } catch (err: any) {
      // todo: fix this
      if (err.response?.errors?.[0]?.message?.includes('Email in use.')) {
        form.setErrors({ email: 'Email already exists!' });
      }
    }
  };

  return (
    <>
      <form className={classes.form} onSubmit={form.onSubmit(onSubmit)}>
        <Input placeholder="first name" {...form.getInputProps('firstName')} />
        <Input placeholder="last name" {...form.getInputProps('lastName')} />
        <TextInput placeholder="email" {...form.getInputProps('email')} />
        <PasswordStrength placeholder="password" {...form.getInputProps('password')} />
        <PasswordInput placeholder="confirm password" {...form.getInputProps('confirmPassword')} />
        <Button size="md" type="submit">
          Register
        </Button>
      </form>
    </>
  );
};
