/* eslint-disable camelcase */
import { Button, Group, Input, Select, createStyles } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useEffect } from 'react';

import { config } from '../../config';
import { useMyUser, useMarketplaces } from '../../hooks';
import { useUpdateMyUser } from '../../hooks/graphql/useUpdateMyUser';

const useStyles = createStyles(() => ({
  form: {
    '&> div': {
      marginBottom: 10,
    },
  },
}));

export const SellerAccount = () => {
  const { data: { retrieveMyUser: user } = {} } = useMyUser();

  const updateMyUser = useUpdateMyUser();

  const { data: { marketplaces } = { marketplaces: [] } } = useMarketplaces();

  const { classes } = useStyles();

  const navigateTo = () => {
    const serverUrl = config.API_URL.replace('0.0.0.0', 'localhost');
    const serverAuthUrl = `${serverUrl}/auth/seller-amazon`;
    window.location.href = serverAuthUrl;
  };

  const form = useForm({
    initialValues: {
      refreshToken: '',
      clientId: '',
      clientSecret: '',
      marketplaceId: '',
    },
  });

  const handleSubmit = async (values) => {
    updateMyUser.mutate({
      sellerData: {
        client_id: values.clientId,
        client_secret: values.clientSecret,
        refresh_token: values.refreshToken,
        marketplaceId: values.marketplaceId,
      },
    });
  };

  useEffect(() => {
    if (user?.sellerApis?.amazon) {
      form.setValues({
        clientId: user?.sellerApis?.amazon.client_id,
        clientSecret: user?.sellerApis?.amazon.client_secret,
        refreshToken: user?.sellerApis?.amazon.refresh_token,
        marketplaceId: user?.sellerApis?.amazon.marketplaceId,
      });
    }
  }, [user]);

  const onMarketplaceChange = (marketplaceId) => {
    form.setFieldValue('marketplaceId', marketplaceId);
  };

  return (
    <form onSubmit={form.onSubmit(handleSubmit)} className={classes.form}>
      <Group>
        <Input.Wrapper label="client_id">
          <Input {...form.getInputProps('clientId')} />
        </Input.Wrapper>
        <Input.Wrapper label="client_secret">
          <Input {...form.getInputProps('clientSecret')} />
        </Input.Wrapper>
        <Input.Wrapper label="refresh_token">
          <Input {...form.getInputProps('refreshToken')} />
        </Input.Wrapper>
      </Group>
      <Group>
        <Select
          label="marketplace"
          data={marketplaces.map((m) => ({ value: m.marketplaceId, label: m.country }))}
          onChange={onMarketplaceChange}
          value={form.values.marketplaceId}
        ></Select>
      </Group>
      <Button type="submit">Update</Button>
    </form>
  );
};
