import { Button, Input, PasswordInput, createStyles } from '@mantine/core';
import { useForm } from '@mantine/form';

import { useLogin } from '../../hooks';

const useStyles = createStyles(() => ({
  form: {
    '& > div': {
      width: '100%',
      marginBottom: 20,
    },
  },
}));

export const Login = () => {
  const { classes } = useStyles();

  const form = useForm({
    initialValues: {
      email: '',
      password: '',
    },
    validate: {
      email: value => !/^\S+@\S+$/.test(value),
      password: value => !value,
    },
  });

  const login = useLogin();

  const handleLogin = async (data: { email: string; password: string }) => login.mutate(data);

  return (
    <>
      <form className={classes.form} onSubmit={form.onSubmit(handleLogin)}>
        <Input placeholder="email" name="email" {...form.getInputProps('email')} />
        <PasswordInput placeholder="password" name="password" {...form.getInputProps('password')} />
        <Button size="md" type="submit">
          Login
        </Button>
      </form>
    </>
  );
};
