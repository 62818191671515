import { Navigate, useLocation } from 'react-router-dom';

import { useMyUser } from '../hooks';

interface ProtectedRouteProps {
  redirectTo: string;
  children: React.ReactNode;
}
export const ProtectedRoute: React.FC<ProtectedRouteProps> = props => {
  const { isLoading, data: { retrieveMyUser: user } = {} } = useMyUser();

  const location = useLocation();

  if (isLoading) return null;

  if (!!user && !user?.hasSellerAccount && location.pathname !== '/settings') {
    return <Navigate to={'/settings?tab=sellerAccount'} />;
  }

  if (!user) return <Navigate to={props.redirectTo} replace />;

  return props.children;
};
