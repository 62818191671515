import { Navigate } from 'react-router-dom';

import { TokenService } from '../services';

interface DeactivateRouteProps {
  redirectTo: string;
  children: React.ReactNode;
}
export const DeactivateRoute: React.FC<DeactivateRouteProps> = props => {
  const canDeactivate = !!TokenService.getToken();
  return !canDeactivate ? props.children : <Navigate to={props.redirectTo} replace />;
};
