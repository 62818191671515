import { Button, Group, Input, createStyles } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useEffect } from 'react';

import { useMyUser } from '../../hooks';
import { useUpdateMyUser } from '../../hooks/graphql/useUpdateMyUser';

const useStyles = createStyles(() => ({
  form: {
    '& > div': {
      marginBottom: 10,
    },
  },
}));

export const EditUser = () => {
  const { classes } = useStyles();

  const { data: { retrieveMyUser: user } = {} } = useMyUser();

  const updateMyUser = useUpdateMyUser();

  const form = useForm({
    initialValues: {
      email: '',
      firstName: '',
      lastName: '',
    },
    validate: {
      email: (value) => !/^\S+@\S+$/.test(value),
    },
  });

  useEffect(() => {
    if (user?._id) {
      form.setValues({
        email: user.email || '',
        firstName: user.firstName || '',
        lastName: user.lastName || '',
      });
    }
  }, [user]);

  const handleSubmit = async (values) => {
    updateMyUser.mutate({
      generalData: {
        email: values.clientId,
        firstName: values.clientSecret,
        lastName: values.refreshToken,
      },
    });
  };

  return (
    <>
      <form className={classes.form} onSubmit={form.onSubmit(handleSubmit)}>
        <Group>
          <Input.Wrapper label="first name">
            <Input {...form.getInputProps('firstName')} />
          </Input.Wrapper>

          <Input.Wrapper label="last name">
            <Input {...form.getInputProps('lastName')} />
          </Input.Wrapper>
        </Group>
        <Group>
          <Input.Wrapper label="Email">
            <Input {...form.getInputProps('email')} />
          </Input.Wrapper>
        </Group>
        <Button type="submit" disabled>
          Update
        </Button>
      </form>
    </>
  );
};
