import { useQuery } from '@tanstack/react-query';

import { graphql } from '../../api';
import { useClient } from './useClient';

const customsTrackingListQuery = graphql(`
  query CustomsTrackings {
    customsTrackings {
      items {
        status
        shipmentId
        orderId
        updatedAt
        duration
        MRN
      }
      meta {
        total
        unread
      }
    }
  }
`);

export const useCustomsTrackingList = () => {
  const client = useClient();
  return useQuery(['customsTrackingList'], async () => client.request(customsTrackingListQuery, {}), {
    staleTime: Infinity,
  });
};
